<template>
    <div class="about">
      <script v-html=navigationJsonLd() type="application/ld+json"/>

        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
                        Gruppen
                    </h1>
                    <h2 class="subtitle is-italic has-text-centered">
                        Denn wo zwei oder drei versammelt sind in meinem Namen, da bin ich mitten unter ihnen.
                        Matthäus 18,20
                    </h2>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">

                <div class="columns is-multiline">
                    <div class="column">
                        <GruppenCard title="Hauskreis" zielgruppe="Erwachsene" image="gruppen/Hauskreis.jpg"
                                     termine="Jeden 2. Mittwoch"
                                     beschreibung="Rund zehn Hauskreise, verteilt im ganzen Stadtgebiet, bilden die Grundlage für aktives Gemeindeleben auf persönlicher Ebene. Gespräche und Fragen, für die sonntags nach dem Gottesdienst manchmal keine Zeit bleibt, können hier in geschütztem Rahmen behandelt werden. Die Treffen wechseln sich mittwochs mit der Bibel- und Gebetsstunde (in der Gemeinde) ab."
                                     ansprechpartner="Andreas"
                                     ansprechpartnerBild="andreas_h.jpg"
                                     ansprechpartnerMail="hauskreis"
                        />
                    </div>
                    <div class="column">
                        <GruppenCard title="MidiKids" zielgruppe="4-8 Jahre" image="gruppen/Midikids.jpg"
                                     termine="alle 4 Wochen Samstags"
                                     beschreibung="Die MidiKids treffen sich jeden 3. Samstag im Monat von 15.00 Uhr bis 17.30 Uhr in der Christlichen Gemeinde und richten sich an Kinder zwischen 4 und 8 Jahren. Während deren Eltern z.B. Zeit für Einkäufe haben, werden die Kinder von einer pädagogischen Fachkraft und weiteren Helfern betreut. Spiel und Spaß drehen sich dabei immer um ein Thema, z.B. Holzeisenbahn, Lego oder Winterspielplatz. Alle Kinder (und deren Eltern) dieser Altersgruppe sind herzlich eingeladen, vorbeizuschauen."
                                     ansprechpartner="Florian"
                                     ansprechpartnerBild="florian.jpg"
                                     ansprechpartnerMail="midikids"/>
                    </div>
                    <div class="column">
                        <GruppenCard title="KidsPoint" zielgruppe="8-13 Jahre"
                                     image="gruppen/kidspoint.jpg"
                                     termine="Jeden Freitag um 17:30 Uhr"
                                     beschreibung="Spiele, Spaß und Spannung gibts im KidsPoint, der freitags um 17.30 Uhr im Gemeindehaus startet. Kindgerecht werden erste Schritte mit Gott und das Leben mit ihm im Schulalltag mitgegeben. Dabei sind Freundschaften knüpfen und der respektvolle Umgang miteinander genauso wichtig wie das Hören von biblischen Geschichten."
                                     ansprechpartner="Aaron"
                                     ansprechpartnerBild="aaron.jpg"
                                     ansprechpartnerMail="jungschar"/>
                    </div>
                    <div class="column">
                        <GruppenCard title="Teenies" zielgruppe="12-18 Jahre" image="gruppen/Teenies.jpg"
                                     termine="Jeden Freitag um 19.30 Uhr"
                                     beschreibung="Der Teeniekreis trifft sich freitags um 19.30 Uhr im Jugendraum der CG. In entspannter Atmosphäre werden Freundschaften gepflegt, Probleme ausgetauscht und sich der Frage gestellt, wie man zwischen Schule, Pubertät und Gruppenzwang seinen Glauben authentisch leben kann. Dabei kommen gemeinsame Aktivitäten und Ausflüge nicht zu kurz."
                                     ansprechpartner="Daniel"
                                     ansprechpartnerBild="daniel_b.jpg"
                                     ansprechpartnerMail="teens"/>
                    </div>
                    <div class="column">
                        <GruppenCard title="Junge Erwachsene (AC)" zielgruppe="18-30 Jahre" image="gruppen/Ac.jpg"
                                     termine="Dienstags 19:30 Uhr"
                                     beschreibung="Die jungen Erwachsenen, auch “AC” genannt, treffen sich dienstags im 14-tägigen Rhythmus abwechselnd zusammen im Gemeindehaus oder in kleineren Gruppen bei Leuten Zuhause. Beim “Groß-AC” in der Gemeinde steht der Lobpreis und eine Andacht im Mittelpunkt des Abends, die “klein-ac’s” bieten Raum fürs gemeinsame Kochen und persönlichen Austausch über die Bibel, Glaubensfragen und Alltagsprobleme. Im Kalender unter Veranstaltungen sind die Treffen in der CG eingetragen. Wer in eine kleinere Gruppe reinschauen möchte, darf sich gerne an Franzi wenden."
                                     ansprechpartner="Franzi"
                                     ansprechpartnerBild="franzi.jpg"
                                     ansprechpartnerMail="ac"/>
                    </div>
                    <div class="column">
                        <GruppenCard title="Phoebe-Treff" zielgruppe="Frauen 18-99 Jahre"
                                     image="gruppen/phoebetreff.jpg"
                                     termine="Einmal im Montag Mittwochs"
                                     beschreibung="Im Phoebe-Treff tauschen sich Frauen jeden 1. Mittwoch im Monat (9.30 Uhr) im Gemeindehaus über ein Buch aus und fragen danach, was die Bibel zum Thema sagt. Die Teilnehmerinnen ermutigen sich gegenseitig, das Gehörte auch im Alltag umzusetzen und tauschen sich darüber aus. Dadurch soll vor allem die Beziehung zu Gott gestärkt werden."
                                     ansprechpartner="Christiane"
                                     ansprechpartnerBild="christiane_g.jpg"
                                     ansprechpartnerMail="frauen"/>
                    </div>
                    <div class="column">
                        <GruppenCard title="Seniorenkreis" zielgruppe="60+ Jahre" image="gruppen/senioren.jpg"
                                     termine="Jeden 2. Donnerstag"
                                     beschreibung="Der Seniorenkreis trifft sich jeden 2. Donnerstag im Monat um 14.30 Uhr (April bis September um 15 Uhr) im Gemeindehaus und bietet Gelegenheit zum persönlichen Austausch und einer gemeinsamen Zeit mit Gott. Fragen des Älterwerdens und was die Bibel dazu sagt finden genauso Gehör wie Hilfsangebote und Ausflugsmöglichkeiten."
                                     ansprechpartner="Willi"
                                     ansprechpartnerBild="willi_b.jpg"
                                     ansprechpartnerMail="senioren"/>
                    </div>
                  <div class="column">
                   &nbsp;
                  </div>
                  <div class="column">
                    &nbsp;
                  </div>
                </div>
            </div>
        </section>

    </div>

</template>


<script>
// @ is an alias to /src
import GruppenCard from '@/components/GruppeCard.vue'

export default {
        name: 'gruppen',
        components: {
            GruppenCard
        },
        methods: {
          navigationJsonLd() {
            var jsonLdBreadcrumb= {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "CG Karlsruhe",
                "item": "https://www.cg-karlsruhe.de"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Gruppen",
                "item": "https://www.cg-karlsruhe.de/gruppen"
              }]
            };
            return jsonLdBreadcrumb;
          }
        }
    }
</script>